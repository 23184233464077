var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-select", {
    attrs: {
      label: "Search Filters",
      items: _vm.filters,
      "item-text": "name",
      "item-value": "key",
      "background-color": "input",
      "menu-props": { offsetY: true, maxHeight: "600" },
      multiple: "",
      outlined: "",
      "hide-details": "",
      "small-chips": "",
      dense: "",
    },
    scopedSlots: _vm._u([
      {
        key: "selection",
        fn: function (ref) {
          var attrs = ref.attrs
          var item = ref.item
          return [
            _c(
              "v-chip",
              _vm._b(
                {
                  attrs: {
                    color: item.color || null,
                    dark: Boolean(item.color),
                    small: "",
                  },
                },
                "v-chip",
                attrs,
                false
              ),
              [
                _vm._v(" " + _vm._s(_vm.getFilterText(item)) + " "),
                _c(
                  "v-icon",
                  {
                    attrs: { small: "", right: "" },
                    on: {
                      click: function ($event) {
                        return _vm.removeFilter(item.key, true)
                      },
                    },
                  },
                  [_vm._v(" $delete ")]
                ),
              ],
              1
            ),
          ]
        },
      },
      {
        key: "item",
        fn: function (ref) {
          var item = ref.item
          return [
            _c(
              "v-list-item",
              [
                _c(
                  "v-list-item-content",
                  [
                    item.type === "text"
                      ? _c("v-text-field", {
                          staticClass: "pl-3",
                          attrs: {
                            label: item.name,
                            "background-color": "input",
                            clearable: "",
                            dense: "",
                            "hide-details": "",
                            outlined: "",
                          },
                          on: {
                            "click:clear": function ($event) {
                              return _vm.removeFilter(item.key)
                            },
                            keyup: function ($event) {
                              $event.stopPropagation()
                              return _vm.handleInputValue(item)
                            },
                          },
                          model: {
                            value: _vm.params["" + item.key],
                            callback: function ($$v) {
                              _vm.$set(_vm.params, "" + item.key, $$v)
                            },
                            expression: "params[`${item.key}`]",
                          },
                        })
                      : _vm._e(),
                    item.type === "select"
                      ? _c("v-autocomplete", {
                          staticClass: "pl-3",
                          attrs: {
                            items:
                              _vm.activeInput === "page_name"
                                ? _vm.pages
                                : _vm.departments,
                            label: item.name,
                            "menu-props": { maxHeight: 225 },
                            "auto-select-first": "",
                            "background-color": "input",
                            clearable: "",
                            dense: "",
                            "hide-details": "",
                            "item-text":
                              _vm.activeInput === "page_name"
                                ? "page_name"
                                : "department",
                            "item-value":
                              _vm.activeInput === "page_name"
                                ? "page_name"
                                : "department",
                            outlined: "",
                          },
                          on: {
                            click: function ($event) {
                              _vm.activeInput = item.key
                            },
                            change: function ($event) {
                              return _vm.handleInputValue(item)
                            },
                            "click:clear": function ($event) {
                              return _vm.removeFilter(item.key)
                            },
                          },
                          model: {
                            value: _vm.params["" + item.key],
                            callback: function ($$v) {
                              _vm.$set(_vm.params, "" + item.key, $$v)
                            },
                            expression: "params[`${item.key}`]",
                          },
                        })
                      : _vm._e(),
                    item.type === "boolean"
                      ? _c("v-checkbox", {
                          staticClass: "pl-3 mt-0",
                          attrs: { label: "Shippers/Pallets" },
                          on: {
                            change: function ($event) {
                              return _vm.handleInputValue(item)
                            },
                          },
                          model: {
                            value: _vm.params["" + item.key],
                            callback: function ($$v) {
                              _vm.$set(_vm.params, "" + item.key, $$v)
                            },
                            expression: "params[`${item.key}`]",
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        },
      },
    ]),
    model: {
      value: _vm.selectedFilters,
      callback: function ($$v) {
        _vm.selectedFilters = $$v
      },
      expression: "selectedFilters",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }